import Head from 'next/head'
import * as React from 'react'

import Footer from '../Footer'
import Header from '../Header'
import { getBrandName } from '../../utils/substores.config'
import useAuthStore from '../../state/auth/auth.store'
import { useQuery } from '@apollo/client'
import { Whoami } from '../../types/whoami'
import { WHO_AM_I } from '../../graphql/queries'

export default function Layout(props) {
  const [showChild, setShowChild] = React.useState(false)
  const setWhoami = useAuthStore(s => s.setWhoami)
  useQuery<Whoami>(WHO_AM_I, {
    notifyOnNetworkStatusChange: true,
    onCompleted: data => setWhoami(data.whoami),
  })

  // Wait until after client-side hydration to show
  React.useEffect(() => {
    setShowChild(true)
  }, [])

  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{getBrandName('Justdrive')} - bil i 120 dage</title>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: "ntksjita",
          custom_launcher_selector: '#intercom-custom-launcher'
        };`,
          }}
        ></script>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
      // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/ntksjita'
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/ntksjita';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
          }}
        ></script>

        <script type="text/javascript" src="https://unpkg.com/flowbite@1.6.0/dist/flowbite.min.js"></script>
      </Head>

      <Header />

      <main id="main">{!showChild ? <div className="h-screen"></div> : props.children}</main>

      <Footer />
    </div>
  )
}
